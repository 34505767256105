import { createAction } from "typesafe-actions";
import { RequestState } from "../constants/request-state";
import { User } from "../types/management/User";
import { UserCreationRequest } from "../types/management/UserCreationRequest";

const approve = createAction('userRequestAdmin/APPROVE',
    resolve => (userRequestId: number, concurrencyStamp: Date) => resolve({ userRequestId, concurrencyStamp }));
const block = createAction('userRequestAdmin/BLOCK',
    resolve => (userRequestId: number, concurrencyStamp: Date, blockReason: string) => resolve({ userRequestId, concurrencyStamp, blockReason }));
const unblock = createAction('userRequestAdmin/UNBLOCK',
    resolve => (userRequestId: number, concurrencyStamp: Date) => resolve({ userRequestId, concurrencyStamp }));
const deleteUserRequest = createAction('userRequestAdmin/DELETE_USER_REQUEST',
    resolve => (userRequestId: number) => resolve({ userRequestId }));
const setOperationState = createAction('userRequestAdmin/SET_OPERATION_STATE',
    resolve => (userRequestId: number, requestState: RequestState) => resolve({ userRequestId, requestState }));
const userRequestApproved = createAction('userRequestAdmin/USER_REQUEST_APPROVED',
    resolve => (userRequestId: number, user: User) => resolve({ userRequestId, user }));
const userRequestBlocked = createAction('userRequestAdmin/USER_REQUEST_BLOCKED',
    resolve => (userRequest: UserCreationRequest) => resolve({ userRequest }));
const userRequestUnblocked = createAction('userRequestAdmin/USER_REQUEST_UNBLOCKED',
    resolve => (userRequest: UserCreationRequest) => resolve({ userRequest }));
const userRequestDeleted = createAction('userRequestAdmin/USER_REQUEST_DELETED',
    resolve => (userRequestId: number) => resolve({ userRequestId }));
const getPendingUserRequestCount = createAction('userRequestAdmin/USER_REQUEST_GET_PENDING_COUNT', resolve => () => resolve({}));
const setPendingUserRequestCount = createAction('userRequestAdmin/USER_REQUEST_SET_PENDING_COUNT',
    resolve => (count: number, requestState: RequestState) => resolve({ count, requestState }));

export const userRequestAdminActions = {
    approve,
    block,
    unblock,
    deleteUserRequest,
    setOperationState,
    userRequestApproved,
    userRequestBlocked,
    userRequestUnblocked,
    setPendingUserRequestCount,
    getPendingUserRequestCount,
    userRequestDeleted
};
