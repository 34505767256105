import { saveAs } from 'file-saver';
import { bwicDateFilterOptions, tradesActions as actionTypes } from '../constants';
import { tradeReportingService } from '../services/tradeReportingService';
import { errorActions } from './error.actions';
import { getDateRangeOption, getSearchDateRange, isRequesting } from '../utils';
import { RequestState } from '../constants/request-state';
import { queryStringSerializer } from '../utils/filtering';
import { dateFrom, dateTo, reportedStatuses, tradesStatuses, settlementStatuses } from '../utils/filtering/serializers/serializer.definitions';
import { TradeSettlementStatus, tradeSettlementStatusTitle } from '../types/settlement/TradeSettlementStatus';
import { tradeStatuses } from '../constants/bidding';
import { history } from '../history';

function getTradeStatusFilter(filters) {
    const statuses = [];
    if (filters.pending) statuses.push(tradeStatuses.pending.key);
    if (filters.confirmed) statuses.push(tradeStatuses.affirmed.key);
    if (filters.rejected) statuses.push(tradeStatuses.rejected.key);

    return statuses;
}

function getSettlementStatusFilter(filters) {
    const statuses = [];

    if (filters.allocationStatus.unsettled) statuses.push(TradeSettlementStatus.Unsettled);
    if (filters.allocationStatus.pendingSettlement) statuses.push(TradeSettlementStatus.PendingSettlement);
    if (filters.allocationStatus.pendingAllocation) statuses.push(TradeSettlementStatus.PendingAllocation);
    if (filters.allocationStatus.pendingProceeds) statuses.push(TradeSettlementStatus.PendingProceeds);
    if (filters.allocationStatus.settled) statuses.push(TradeSettlementStatus.Settled);

    return statuses;
}


export const tradesActions = {
    init,
    reset,
    filterReportedChange,
    filterNotReportedChange,
    search,
    setTradeReportedFlag,
    resetFilter,
    exportSearchResult,
    filterDateChange,
    filterCustomDateChange,
    tradesToggleConfirmed,
    tradesTogglePending,
    tradesToggleRejected,
    tradesToggleAllocationStatusUnsettled,
    tradesToggleAllocationStatusPendingSettlement,
    tradesToggleAllocationStatusPendingAllocation,
    tradesToggleAllocationStatusPendingProceeds,
    tradesToggleAllocationStatusSettled
};

function init(sortingField, sortDirection) {
    return dispatch => {
        const queryString = history.location.search;
        let from = '';
        let to = '';
        let statusOptions = [];
        let sellementStatusOptions = [];
        const serializers = [
            dateFrom(parsedDate => from = parsedDate),
            dateTo(parsedDate => to = parsedDate),
            reportedStatuses(reported => reported.map(r => r === 'No' ? dispatch(filterNotReportedChange()) : dispatch(filterReportedChange()))),
            tradesStatuses(parsedStatuses => statusOptions = parsedStatuses),
            settlementStatuses(parsedStatuses => sellementStatusOptions = parsedStatuses),
        ];
        queryStringSerializer.deserialize(queryString, serializers);
        const { option, customRange } = getDateRangeOption(from, to);

        dispatch(filterDateChange(option));
        dispatch(filterCustomDateChange(customRange));
        statusOptions.map(statusTitle => {
            if (statusTitle === tradeStatuses.pending.title) return dispatch(tradesTogglePending());
            if (statusTitle === tradeStatuses.affirmed.title) return dispatch(tradesToggleConfirmed());
            if (statusTitle === tradeStatuses.rejected.title) return dispatch(tradesToggleRejected());
            return null;
        })
        sellementStatusOptions.map(statusTitle => {
            if (statusTitle === tradeSettlementStatusTitle[TradeSettlementStatus.Unsettled]) {
                return dispatch(tradesToggleAllocationStatusUnsettled());
            }
            if (statusTitle === tradeSettlementStatusTitle[TradeSettlementStatus.PendingSettlement]) {
                return dispatch(tradesToggleAllocationStatusPendingSettlement());
            }
            if (statusTitle === tradeSettlementStatusTitle[TradeSettlementStatus.PendingAllocation]) {
                return dispatch(tradesToggleAllocationStatusPendingAllocation());
            }
            if (statusTitle === tradeSettlementStatusTitle[TradeSettlementStatus.PendingProceeds]) {
                return dispatch(tradesToggleAllocationStatusPendingProceeds());
            }
            if (statusTitle === tradeSettlementStatusTitle[TradeSettlementStatus.Settled]) {
                return dispatch(tradesToggleAllocationStatusSettled());
            }
            return null;
        })
        dispatch(search(sortingField, sortDirection));
    };
}

function reset() {
    return { type: actionTypes.TRADES_RESET };
}

function tradesToggleConfirmed() {
    return {
        type: actionTypes.TOGGLE_CONFIRMED
    };
}
function tradesTogglePending() {
    return {
        type: actionTypes.TOGGLE_PENDING
    };
}
function tradesToggleRejected() {
    return {
        type: actionTypes.TOGGLE_REJECTED
    };
}

function tradesToggleAllocationStatusUnsettled() {
    return {
        type: actionTypes.TOGGLE_UNSETTLED
    };
}
function tradesToggleAllocationStatusPendingSettlement() {
    return {
        type: actionTypes.TOGGLE_PENDING_SETTLEMENT
    };
}
function tradesToggleAllocationStatusPendingAllocation() {
    return {
        type: actionTypes.TOGGLE_PENDING_ALLOCATION
    };
}
function tradesToggleAllocationStatusPendingProceeds() {
    return {
        type: actionTypes.TOOGLE_PENDING_PROCEEDS
    };
}
function tradesToggleAllocationStatusSettled() {
    return {
        type: actionTypes.TOGGLE_SETTLED
    };
}

function filterDateChange(option) {
    return (dispatch, getState) => {
        const { filter } = getState().trades;
        dispatch({
            type: actionTypes.FILTER_DATE_CHANGE,
            option: {
                key: option.key,
                title: option.title
            }
        });

        if (
            option.key !== bwicDateFilterOptions.custom.key &&
            filter.customDateRange &&
            filter.customDateRange.from
        ) {
            dispatch(filterCustomDateChange({}));
        }
    }
}

function filterCustomDateChange(dateRange) {
    return {
        type: actionTypes.FILTER_CUSTOM_DATE_CHANGE,
        dateRange
    };
}

function filterReportedChange() {
    return dispatch => {
        dispatch({ type: actionTypes.TRADES_FILTER_REPORTED });
    }
}

function filterNotReportedChange() {
    return dispatch => {
        dispatch({ type: actionTypes.TRADES_FILTER_NOT_REPORTED });
    }
}

function search(sortingField, sortDirection, isResetTrades) {
    return (dispatch, getState) => {
        const { trades } = getState();
        const { filter } = trades;
        let page = trades.page;
        if (isRequesting(trades.searchRequestState)){
            return;
        }

        if (isResetTrades) {
            dispatch(resetTrades());
            page = 1;
        }

        dispatch(setSearchInProgress(RequestState.request));

        const isReported = geReportedFlag(filter.reported, filter.notReported);
        const excludeOnBehalf = filter.reported && filter.notReported;
        const searchDateRange = getSearchDateRange(filter);
        const filterDateFrom = searchDateRange.dateFrom || null;
        const filterDateTo = searchDateRange.dateTo || null;
        const tradeStatusesValue = getTradeStatusFilter(filter);
        const settlementStatusesValue = getSettlementStatusFilter(filter);

        tradeReportingService
            .search({
                isReported,
                excludeOnBehalf,
                dateFrom: filterDateFrom,
                dateTo: filterDateTo,
                tradeStatuses: tradeStatusesValue,
                settlementStatuses: settlementStatusesValue,
                sortingField,
                sortDirection,
                page,
                pageSize: 50,

            })
            .then(searchSuccess)
            .catch(e => {
                dispatch(setSearchInProgress(RequestState.failure));
                dispatch(errorActions.criticalError(e));
            });

        function searchSuccess(response) {
            const serializers = [dateFrom(), dateTo(), reportedStatuses(), tradesStatuses(), settlementStatuses()];
            const criteria = {
                ...searchDateRange,
                reported: getReportedCriteria(filter.reported, filter.notReported),
                statuses: tradeStatusesValue ? tradeStatusesValue.map(key => tradeStatuses.getByKey(key).title) : [],
                settlementStatuses: settlementStatusesValue ? settlementStatusesValue.map(s => tradeSettlementStatusTitle[s]) : []
            };

            const queryString = queryStringSerializer.serialize(criteria, serializers);

            if (queryString) {
                history.replace(history.location.pathname + '?' + queryString);
            }

            dispatch(storeResponse(response, page += 1));
            dispatch(setSearchInProgress(RequestState.success))
        }
    };
}

function geReportedFlag(reported, notReported) {
    if ((reported && notReported) || (!reported && !notReported)) {
        return undefined;
    }

    return reported;
}


function storeResponse(response, page) {
    return {
        type: actionTypes.TRADES_STORE_TRADES,
        payload: { trades: response.result, totalRecordNumber: response.totalRecordNumber, page }
    };
}

function resetTrades() {
    return { type: actionTypes.TRADES_RESET_TRADES }
}

function setSearchInProgress(requestState) {
    return {
        type: actionTypes.TRADES_SEARCH_IN_PROGRESS,
        payload: { requestState }
    };
}

function resetFilter() {
    return dispatch => {
        dispatch({ type: actionTypes.TRADES_RESET_FILTER });
    };
}

function setTradeReportedFlag(tradeId, isReported) {
    return dispatch => {
        dispatch(setReportedRequestState(tradeId, true));

        tradeReportingService.setReportedFalg(tradeId, isReported)
            .then(
                () => dispatch(tradeReportedFlagChange(tradeId, isReported)),
                e => dispatch(errorActions.unexpectedError(e))
            ).then(() => dispatch(setReportedRequestState(tradeId, false)));

        function tradeReportedFlagChange(tradeId, isReported) {
            return {
                type: actionTypes.TRADES_REPORTED_FLAG,
                tradeId,
                isReported
            };
        }

        function setReportedRequestState(tradeId, inProgress) {
            return {
                type: actionTypes.TRADES_REPORTED_REQUEST_STATE,
                tradeId,
                inProgress
            };
        }
    };
}

function setRequestingExportTrades(status) {
    return { type: actionTypes.TRADES_SET_IS_REQUESTING_EXPORT_TRADES, payload: { status } }
}

function exportSearchResult(sortingField, sortDirection) {
    return (dispatch, getState) => {
        const { filter } = getState().trades;
        const { dateFrom = null, dateTo = null } = getSearchDateRange(filter);
        const isReported = geReportedFlag(filter.reported, filter.notReported);
        const excludeOnBehalf = filter.reported && filter.notReported;
        const tradeStatusesValue = getTradeStatusFilter(filter);
        const settlementStatusesValue = getSettlementStatusFilter(filter);
        dispatch(setRequestingExportTrades(true));
        tradeReportingService
            .exportSearchResult(
                isReported,
                dateFrom,
                dateTo,
                excludeOnBehalf,
                tradeStatusesValue,
                settlementStatusesValue,
                sortingField,
                sortDirection
            )
            .then(file => saveAs(file.blob, file.name || 'list_of_trades.csv'))
            .catch(e => errorActions.unexpectedError(e))
            .finally(() => dispatch(setRequestingExportTrades(false)));
    };
}


function getReportedCriteria(reported, notReported) {
    const result = [];
    if (reported) {
        result.push('Yes')
    }
    if (notReported) {
        result.push('No')
    }

    return result;
}
