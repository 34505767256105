import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { profileActions as actionTypes, constants } from '../constants';
import { accountService } from '../services';
import { errorActions } from './error.actions';
import { accountActions } from './account.actions';
import { notificationActions } from './notification.actions';

export const tfaSettingsActions = {
    deactivateSmsConfirmation,
    saveIdentityPhone,
    submitPhoneVerificationCode,
    invalidPhoneVerificationCode,
    requestPhoneVerificationCode,
    storeSmsActivationRequestedDate,
    identityPhoneEdit
};

type TDispatch = ThunkDispatch<any, void, AnyAction>;

function deactivateSmsConfirmation() {
    return (dispatch: TDispatch) => {
        dispatch(deactivateSmsConfirmationState(true));

        accountService
            .deleteIdentityPhoneNumber()
            .then(() => dispatch({ type: actionTypes.PROFILE_SMS_DEACTIVATED }))
            .catch((e: unknown) => dispatch(errorActions.unexpectedError(e)))
            .finally(() => dispatch(deactivateSmsConfirmationState(false)));
    }
}

function deactivateSmsConfirmationState(inProgress: boolean) {
    return {
        type: actionTypes.PROFILE_SMS_DEACTIVATING,
        inProgress
    };
}

function saveIdentityPhone(phone: string) {
    return async (dispatch: TDispatch) => {
        dispatch(identityPhoneSaving(true));

        try {
            await accountService.saveIdentityPhoneNumber(phone);
            await accountService.requestPhoneVerificationCode();

            dispatch({ type: actionTypes.PROFILE_IDENTITY_PHONE_SAVED, phone });
            dispatch(storeSmsActivationRequestedDate(new Date()));
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));

        } finally {
            dispatch(identityPhoneSaving(false));
        }
    };
}

function storeSmsActivationRequestedDate(date: Date | undefined) {
    return {
        type: actionTypes.PROFILE_SMS_ACTIVATION_CODE_REQUESTED,
        date
    };
}


function identityPhoneSaving(inProgress: boolean) {
    return {
        type: actionTypes.PROFILE_IDENTITY_PHONE_SAVING_STATE,
        inProgress
    };
}

function submitPhoneVerificationCode(code: string) {
    return async (dispatch: TDispatch) => {
        dispatch(verificationCodeSubmitting(true));
        const fingerprint = await accountActions.getFingerprint();

        accountService
            .submitPhoneVerificationCode(code, fingerprint)
            .then(handleResult)
            .catch(() => dispatch(invalidPhoneVerificationCode(true)))
            .finally(() => dispatch(verificationCodeSubmitting(false)));

        function handleResult(result: any) {
            if (result.isValid) {
                dispatch(identityPhoneConfirmed());
            } else if (result.failed) {
                dispatch(storeSmsActivationRequestedDate(undefined));
                dispatch(notificationActions.notificationAddErrorMessage(
                    `Please try to confirm code in ${constants.accountLockTimeoutMinutes} minutes or contact ${process.env.REACT_APP_SALES_EMAIL} to solve the issue`,
                    'Oops, something went wrong'
                ));
            } else {
                dispatch(invalidPhoneVerificationCode(true));
            }
        };
    };
}

function identityPhoneConfirmed() {
    return {
        type: actionTypes.PROFILE_IDENTITY_PHONE_CONFIRMED
    };
}

function verificationCodeSubmitting(inProgress: boolean) {
    return {
        type: actionTypes.PROFILE_VERIFICATION_CODE_SENDING_STATE,
        inProgress
    };
}

function requestPhoneVerificationCode() {
    return async (dispatch: TDispatch) => {
        dispatch(identityPhoneSaving(true));
        dispatch(invalidPhoneVerificationCode(false));

        accountService
            .requestPhoneVerificationCode()
            .then(() => dispatch(storeSmsActivationRequestedDate(new Date())))
            .catch((e: unknown) => dispatch(errorActions.unexpectedError(e)))
            .finally(() => dispatch(identityPhoneSaving(false)));
    };
}

function invalidPhoneVerificationCode(isValid: boolean) {
    return {
        type: actionTypes.PROFILE_PHONE_VERIFICATION_CODE_VALID_STATE,
        isValid
    };
}

function identityPhoneEdit(isEdit: boolean) {
    return {
        type: actionTypes.PROFILE_IDENTITY_PHONE_EDIT,
        isEdit
    };
}
