import { createAction, ActionType } from 'typesafe-actions';
import { RequestState } from '../constants/request-state';
import { TradeAllocationItem } from '../types/trade-allocation/TradeAllocationItem';

const request = createAction('entities/trade-allocation/REQUEST',
    resolve => (tradeId: string) => resolve({ tradeId })
);
const requestResult = createAction('entities/trade-allocation/REQUEST_RESULT',
    resolve => (tradeId: string, requestState: RequestState, tradeAllocationItems: TradeAllocationItem[] = []) =>
        resolve({ tradeId, requestState, tradeAllocationItems })
);
const reset = createAction('entities/trade-allocation/RESET');

const update = createAction('entities/trade-allocation/UPDATE',
    resolve => (tradeId: string, items: TradeAllocationItem[]) => resolve({ tradeId, items })
);

export type TradeAllocationActionType = ActionType<
    typeof request |
    typeof requestResult |
    typeof reset |
    typeof update
>;

export const tradeAllocationActions = {
    request,
    requestResult,
    reset,
    update
};
