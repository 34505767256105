import { userProfilePhotoActionTypes } from '../constants/actionTypes/user-profile-photo-action';
import { accountService, photoService } from '../services';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RequestState } from '../constants/request-state';
import { AppState } from '../types/state/AppState';
import { isRequesting, isRequestSuccess } from '../utils';

type TDispatch = ThunkDispatch<any, void, AnyAction>;

export const userProfilePhotoActions = {
    reset,
    fetchUserImage,
    fetchPhotoThumbnail
};

function reset() {
    return { type: userProfilePhotoActionTypes.USER_PROFILE_PHOTO_RESET };
}

function setUserProfilePhoto(userId: number, imageBlobUrl: string) {
    return {
        type: userProfilePhotoActionTypes.USER_PROFILE_PHOTO_SET_USER_PHOTO,
        payload: { userId, imageBlobUrl }
    }
}

function setPhotoThumbnail(userId: number, imageBlobUrl: string) {
    return {
        type: userProfilePhotoActionTypes.STORE_PHOTO_THUMBNAIL,
        payload: { userId, imageBlobUrl }
    }
}


function setFetchUserPhotoRequestState(userId: number, requestState: RequestState) {
    return (dispatch: TDispatch) => {
        if (userId) {
            dispatch({
                type: userProfilePhotoActionTypes.USER_PROFILE_PHOTO_SET_FETCH_PHOTO_REQUEST_STATE,
                payload: { userId, requestState }
            })
        }
    }
}

function setPhotoThumbnailRequestState(userId: number, requestState: RequestState) {
    return {
        type: userProfilePhotoActionTypes.PHOTO_THUMBNAIL_REQUEST_STATE,
        payload: { userId, requestState }
    }
}


function createBase64FromBlob(response: any) {
    return new Promise<string>((resolve) => {
        if (response && response.blob && response.blob.size) {
            const reader = new FileReader();
            reader.readAsDataURL(response.blob);
            reader.onloadend = function() {
                const base64data = reader.result;
                resolve(String(base64data));
            }
        } else {
            resolve('');
        }
    })
}

function fetchPhotoThumbnail(userId: number) {
    return (dispatch: TDispatch, getState: () => AppState) => {
        const { thumbnailsRequestState } = getState().userProfilePhoto;
        const requestState = thumbnailsRequestState[userId];

        if (isRequesting(requestState) || isRequestSuccess(requestState)) {
            return;
        }

        dispatch(setPhotoThumbnailRequestState(userId, RequestState.request));

        photoService.getThumbnail(userId)
            .then(createBase64FromBlob)
            .then(image => {
                dispatch(setPhotoThumbnailRequestState(userId, RequestState.success));
                dispatch(setPhotoThumbnail(userId, image))
            })
            .catch(() => {
                dispatch(setPhotoThumbnail(userId, ''));
                dispatch(setPhotoThumbnailRequestState(userId, RequestState.failure))
            });
    };
}

function fetchUserImage(userId: number) {
    return (dispatch: TDispatch) => {
        dispatch(setFetchUserPhotoRequestState(userId, RequestState.request));
        accountService.fetchUserImage(userId)
            .then(createBase64FromBlob)
            .then((image: string) => {
                dispatch(setFetchUserPhotoRequestState(userId, RequestState.success));
                dispatch(setUserProfilePhoto(userId, image))
            })
            .catch(() => {
                dispatch(setUserProfilePhoto(userId, ''));
                dispatch(setFetchUserPhotoRequestState(userId, RequestState.failure))
            });
    };
}
