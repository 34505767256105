import { ActionType, createAction } from "typesafe-actions";
import { RequestState } from "../constants/request-state";
import { TradeAllocationTemplate } from "../types/trade-allocation/template/TradeAllocationTemplate";

const request = createAction('entities/trade-allocation-template/REQUEST');
const requestResult = createAction('entities/trade-allocation-template/REQUEST_RESULT',
    resolve => (requestState: RequestState, templates: TradeAllocationTemplate[] = []) =>
        resolve({ requestState, templates })
);
const update = createAction('entities/trade-allocation-template/UPDATE',
    resolve => (template: TradeAllocationTemplate) => resolve({ template })
);
const deleteTemplate = createAction('entities/trade-allocation-template/DELETE',
    resolve => (templateId: number) => resolve({ templateId })
);
const activeChange = createAction('entities/trade-allocation-template/ACTIVE',
    resolve => (activated?: TradeAllocationTemplate, deactivated?: TradeAllocationTemplate) =>
        resolve({ activated, deactivated })
);

export type TradeAllocationTemplateActionType =
    ActionType<
        typeof request |
        typeof requestResult |
        typeof update |
        typeof deleteTemplate |
        typeof activeChange
    >;

export const tradeAllocationTemplateActions = {
    request,
    requestResult,
    update,
    deleteTemplate,
    activeChange
};
