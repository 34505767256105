import { createAction, ActionType } from 'typesafe-actions';
import { Process } from '../types/models/Process';
import { ParsedBwicProcess } from '../types/models/ParsedBwicProcess';

const show = createAction('textRulesPanel/SHOW',
    resolve => (
        rulesText: string,
        goodUntilDate: Date,
        bidsDueDate: Date,
        process: Process,
        parsedProcess: ParsedBwicProcess,
        isParsed: boolean,
        isSameDayBwic: boolean,
        daysToSettle: number,
        bwicReferenceName: string,
    ) => resolve({
        rulesText,
        goodUntilDate,
        bidsDueDate,
        process,
        parsedProcess,
        isParsed,
        isSameDayBwic,
        daysToSettle,
        bwicReferenceName
    }))

const hide = createAction('textRulesPanel/HIDE')

export type TRulesSidePanelActions = ActionType<
    typeof show |
    typeof hide
>;

export const textRulesPanelActions = {
    show,
    hide
}
