import { tradeConfirmActions as actionTypes, errorMessages } from '../constants';
import { biddingService } from '../services';
import { tradeStatuses } from '../constants/bidding';
import { errorActions } from './error.actions';

export const tradeConfirmActions = {
    reset,
    affirmAll,
    affirm,
    reject,
    checkAwaitingTrades,
    setAwaitingBwicTradeStatus,
    visibility
}

function affirmAll() {
    return async (dispatch, getState) => {
        const { tradedPositions, operationsProgress } = getState().tradeConfirm;
        const positions = tradedPositions
            .filter(p =>
                p.trade.status === tradeStatuses.pending.key &&
                !operationsProgress.some(o => o.positionId === p.id && o.isInProgress))
            .map(p => ({ positionId: p.id, tradeReferenceId: p.trade.id }));

        if (positions.length) {
            const operationsProgressUpdate = positions.map(p => ({ positionId: p.positionId, isInProgress: true }));
            dispatch(setAffirmProgress(operationsProgressUpdate));

            biddingService
                .affirm(positions)
                .then(() => dispatch(setAwaitingBwicTradeStatus(positions.map(p => p.positionId), tradeStatuses.affirmed.key)))
                .catch(e => dispatch(errorActions.unexpectedError(e)))
                .finally(() => dispatch(setAffirmProgress(operationsProgressUpdate.map(o => ({ ...o, isInProgress: false })))));
        }
    };
}

function affirm(positionId, tradeReferenceId) {
    return (dispatch, getState) => {
        const operationsProgress = getState().tradeConfirm.operationsProgress;

        if (!checkOperationInProgress(positionId, operationsProgress)) {
            dispatch(setAffirmProgress([{ positionId, isInProgress: true }]));

            biddingService
                .affirm([{ positionId, tradeReferenceId }])
                .then(() => dispatch(setAwaitingBwicTradeStatus([positionId], tradeStatuses.affirmed.key)))
                .catch(e => dispatch(errorActions.unexpectedError(e)))
                .finally(() => dispatch(setAffirmProgress([{ positionId, isInProgress: false }])));
        }
    };
}

function reject(positionId, tradeReferenceId, rejectReason) {
    return (dispatch, getState) => {
        const { operationsProgress } = getState().tradeConfirm;

        if (!checkOperationInProgress(positionId, operationsProgress)) {
            dispatch(setAffirmProgress([{ positionId, isInProgress: true }]));

            biddingService
                .reject({ positionId, tradeReferenceId, rejectReason })
                .then(() => dispatch(setAwaitingBwicTradeStatus([positionId], tradeStatuses.rejected.key, rejectReason)))
                .catch(e => dispatch(errorActions.error(e, errorMessages.unexpectedError)))
                .finally(() => dispatch(setAffirmProgress([{ positionId, isInProgress: false }])));
        }
    };
}

function checkOperationInProgress(positionId, operationsProgress) {
    const progress = operationsProgress.find(o => o.positionId === positionId);

    if (progress) {
        return progress.isInProgress;
    }

    return false;
}

function setAffirmProgress(operationsProgressUpdate) {
    return {
        type: actionTypes.SET_OPERATIONS_PROGRESS,
        operationsProgressUpdate
    };
}

function setAwaitingBwicTradeStatus(positions, status, rejectReason) {
    return (dispatch, getState) => {
        const { visible } = getState().tradeConfirm;

        dispatch({
            type: actionTypes.SET_TRADED_POSITION_STATUS,
            positions,
            status,
            rejectReason
        });

        if (!visible) {
            dispatch(removeProcessedTradedPositions());
        }
    };
}

function setAwaitingTradedPositions(tradedPositions) {
    return {
        type: actionTypes.SET_AWAITING_TRADED_POSITIONS,
        tradedPositions
    };
}

function removeProcessedTradedPositions() {
    return {
        type: actionTypes.REMOVE_PROCESSED_TADED_POSITIONS
    };
}

function checkAwaitingTrades() {
    return dispatch => {
        biddingService
            .checkAwaitingTrades()
            .then(tradedPositions => dispatch(setAwaitingTradedPositions(tradedPositions)))
            .catch(e => dispatch(errorActions.unexpectedError(e)));
    };
}

function visibility(visible) {
    return dispatch => {
        dispatch({
            type: actionTypes.CONFIRM_TRADE_POPUP_VISIBLE,
            visible
        });
        dispatch(removeProcessedTradedPositions())
    }
}

function reset() {
    return {
        type: actionTypes.RESET
    };
}
