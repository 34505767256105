import {
    uploadDocumentsActions as actionTypes
} from '../constants';
import { bwicDealsService, documentsService } from '../services';
import { errorActions } from '../actions';
import { saveAs } from 'file-saver';
import { DealDocumentTypeState } from '../types/state/UploadDocumentsState';

export const uploadDocumentsActions = {
    init,
    reset,
    refresh,
    uploadDialog,
    uploadAllDialog,
    uploadDocument,
    deleteDocument,
    downloadDocument
}

function init(securityIdList) {
    return async dispatch => {
        try {
            const deals = await bwicDealsService.getDeals(securityIdList);
            dispatch(loadDeals(deals));

        } catch (e) {
            dispatch(errorActions.criticalError(e))
        }
    };
}

function loadDeals(deals) {
    return { type: actionTypes.DEALS, deals };
}

function reset() {
    return { type: actionTypes.RESET };
}

function refresh() {
    return (dispatch, getState) => {
        const { deals } = getState().uploadDocuments;
        if (deals?.some(d =>
                d.om?.some(o => o.dateAdded) ||
                d.indenture?.some(o => o.dateAdded) ||
                d.dailyReports?.some(o => o.dateAdded) ||
                d.monthlyReports?.some(o => o.dateAdded) ||
                d.other?.some(o => o.dateAdded))) {
            dispatch({ type: actionTypes.REFRESH_TIME });
        }
    }
}

function uploadDialog(visible, dealReferenceName, documentType) {
    return (dispatch, getState) => {
        const { uploadAll = {} } = getState().uploadDocuments;
        if (!uploadAll.visible) {
            dispatch({
                type: actionTypes.UPLOAD_DIALOG,
                upload: visible ? { visible, dealReferenceName, documentType } : { visible }
            });
        }
    }
}

function uploadAllDialog(visible, deal) {
    return {
        type: actionTypes.UPLOAD_ALL_DIALOG,
        uploadAll: visible ? { visible, deal } : { visible }
    };
}

function uploadDocument(file, dealReferenceName, documentType) {
    return (dispatch, getState) => {
        const uid = Math.floor(Math.random() * 1000000);

        const uploadingDocument = {
            uid,
            progress: 0,
            name: file.name,
            dealReferenceName,
            documentType,
        };

        dispatch(beginDocumentUpload(uploadingDocument));

        const { deals } = getState().uploadDocuments;
        const deal = deals.find(d => d.referenceName === dealReferenceName);

        documentsService
            .uploadAndAssignDocument(file, deal.referenceName, documentType, progress)
            .then(success, failure);

        function progress(e) {
            if (e.lengthComputable) {
                var percentComplete = (e.loaded / e.total) * 100;
                dispatch(progressDocumentUpload(uid, Math.floor(percentComplete)));
            }
        }

        function success(documents) {
            setTimeout(() => {
                dispatch(completeDocumentUpload(uid));

                const document = {
                    id: documents[0].referenceName,
                    type: documentType,
                    name: file.name,
                    dateAdded: new Date()
                };

                const { deals } = getState().uploadDocuments;
                const result = deals.map(d => d.referenceName === dealReferenceName ? update(d, document) : d);

                dispatch(loadDeals(result));
            }, 500);
        }

        function failure(e) {
            dispatch(errorActions.error(e));
            dispatch(completeDocumentUpload(uid));
        }

        function update(deal, document) {
            const merge = (docs = []) => docs.concat(document)

            const copy = { ...deal };
            if (document.type === DealDocumentTypeState.OM) {
                copy.om = merge(copy.om);
            } else if (document.type === DealDocumentTypeState.Indenture) {
                copy.indenture = merge(copy.indenture);
            } else if (document.type === DealDocumentTypeState.DistributionReport) {
                copy.distributionReports = merge(copy.distributionReports);
            } else if (document.type === DealDocumentTypeState.MonthlyReport) {
                copy.monthlyReports = merge(copy.monthlyReports);
            } else {
                copy.other = merge(copy.other);
            }

            return copy;
        }
    }
}

function beginDocumentUpload(uploadingDocument) {
    return {
        type: actionTypes.BEGIN_DOCUMENT_UPLOAD,
        uploadingDocument
    };
}

function progressDocumentUpload(uid, progress) {
    return {
        type: actionTypes.PROGRESS_DOCUMENT_UPLOAD,
        upload: { uid, progress }
    };
}

function completeDocumentUpload(uid) {
    return {
        type: actionTypes.COMPLETE_DOCUMENT_UPLOAD,
        uid
    };
}

function deleteDocument(dealTicker, documentId) {
    return dispatch => {
        dispatch(setRequestStatusDeletingDocument(documentId));
        documentsService
            .deleteDocument(documentId, dealTicker)
            .then(() => dispatch(removeDocumentFromStore(dealTicker, documentId)))
            .catch(e => dispatch(errorActions.unexpectedError(e)))
            .finally(() => dispatch(setRequestStatusDeletingDocument(documentId)));
    };
}

function setRequestStatusDeletingDocument(documentId) {
    return { type: actionTypes.SET_REQUEST_STATUS_DELETING_DOCUMENT, payload: { documentId } }
}

function removeDocumentFromStore(dealTicker, documentId) {
    return (dispatch, getState) => {
        const { deals } = getState().uploadDocuments;
        const result = deals.map(d => d.referenceName === dealTicker ? remove(d, documentId) : d);

        dispatch(loadDeals(result));

        function remove(deal, documentId) {
            const copy = { ...deal };

            copy.om = copy.om.filter(d => d.id !== documentId);
            copy.indenture = copy.indenture.filter(d => d.id !== documentId);
            copy.distributionReports = copy.distributionReports.filter(d => d.id !== documentId);
            copy.monthlyReports = copy.monthlyReports.filter(d => d.id !== documentId);
            copy.other = copy.other.filter(d => d.id !== documentId);

            return copy;
        }
    };
}

function downloadDocument(document) {
    return () => {
        documentsService
            .downloadDocument(document.id)
            .then(file => saveAs(file.blob, document.name));
    };
}