import { Dispatch, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../types/state/AppState";
import { User } from "../types/account/User";
import { VerifyIdentityMethodType } from "../types/account/VerifyIdentityMethodType";
import { verifyIdentityActions as actionTypes } from "../constants/actionTypes/verify.identity.actions";
import { routes } from '../constants/routes';
import { accountActions, errorActions } from '.';
import { accountService } from '../services/account.service';
import { formatUtils } from "../utils";
import { constants } from "../constants";
import { notificationActions } from './notification.actions';
import { history } from "../history";

type TDispatch = ThunkDispatch<any, any, AnyAction>;

export const verifyIdentityActions = {
    verify,
    changeSelectedMethod,
    requestVerificationCode,
    submitVerificationCode,
    verificationMethodReset,
    invalidVerificationCode,
    reset
}

function verify(email: string, phone: string | undefined, pathname: string | undefined) {
    return (dispatch: Dispatch): void => {
        dispatch({
            type: actionTypes.VERIFY_IDENTITY_REQUIRED,
            email: formatUtils.maskEmail(email),
            phone,
            pathname

        });
        history.replace(routes.verifyIdentityRequest);
    };
}

function changeSelectedMethod(selectedMethod: VerifyIdentityMethodType): AnyAction {
    return {
        type: actionTypes.VERIFY_IDENTITY_SET_SELECTED_METHOD,
        selectedMethod
    };
}

function requestVerificationCode() {
    return (dispatch: TDispatch, getState: () => AppState): void => {
        const { selectedMethod } = getState().verifyIdentity;
        const verifyPromise = selectedMethod === VerifyIdentityMethodType.email
            ? accountService.requestIdentityVerificationCodeEmail()
            : accountService.requestIdentityVerificationCodeSms();

        dispatch(invalidVerificationCode(false));
        dispatch(requesting(true));

        verifyPromise
            .then(sent)
            .catch(failed)
            .finally(() => dispatch(requesting(false)));

        function requesting(isRequesting: boolean) {
            return {
                type: actionTypes.VERIFY_IDENTITY_CODE_REUESTING,
                isRequesting
            };
        }
        function sent() {
            dispatch(storeVerificationCodeSentDate(new Date()));
            history.replace(routes.verifyIdentitySubmit);
        }
        function failed(e: { status: number }) {
            if (e.status === 401) {
                history.replace(routes.login);
            } else {
                dispatch(errorActions.unexpectedError(e));
            }
        }
    }
}

function storeVerificationCodeSentDate(sentDate: Date | undefined): AnyAction {
    return {
        type: actionTypes.VERIFY_IDENTITY_CODE_SENT,
        sentDate
    };
}

function submitVerificationCode(code: string, attempt: number) {
    return async (dispatch: TDispatch, getState: () => AppState): Promise<void> => {
        dispatch(submitting(true));

        const fingerprint = await accountActions.getFingerprint();

        accountService.submitIdentityVerificationCode(code, fingerprint)
            .then(success)
            .catch(failed)
            .finally(() => dispatch(submitting(false)))

        function submitting(inProgress: boolean) {
            return {
                type: actionTypes.VERIFY_IDENTITY_CODE_CONFIRMING,
                inProgress
            }
        }

        function success(userData: User) {
            const pathname = getState().verifyIdentity.pathname;
            dispatch(accountActions.loginSuccess(userData, pathname))
        }

        function failed(e: any) {
            if (+e.status === 400) {
                if (attempt >= constants.sendVerificationCodeAttemptsLimit) {
                    history.replace(routes.login)
                    dispatch(notificationActions.notificationAddErrorMessage(
                        `Please try to login again in ${constants.accountLockTimeoutMinutes} minutes or contact ${process.env.REACT_APP_SALES_EMAIL} to solve the issue`,
                        'Oops, something went wrong'
                    ));
                } else {
                    dispatch(invalidVerificationCode(true));
                }
            } else {
                history.replace(routes.login);
            }
        }
    }
}

function invalidVerificationCode(isInvalid: boolean) {
    return (dispatch: Dispatch, getState: () => AppState): void => {
        const { isVerificationCodeInvalid } = getState().verifyIdentity;

        if (isVerificationCodeInvalid !== isInvalid) {
            dispatch({
                type: actionTypes.VERIFY_IDENTITY_CODE_INVALID,
                isInvalid
            });
        }
    };
}

function verificationMethodReset() {
    return (dispatch: TDispatch): void => {
        dispatch(storeVerificationCodeSentDate(undefined));
        dispatch(changeSelectedMethod(VerifyIdentityMethodType.email));
        dispatch(invalidVerificationCode(false));
        history.replace(routes.verifyIdentityRequest);
    };
}

function reset(): AnyAction {
    return {
        type: actionTypes.VERIFY_IDENTITY_RESET
    };
}
