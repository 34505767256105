import { createAction } from "typesafe-actions";
import { BillingContact } from "../types/billing/BillingContact";
import { PaymentCard } from "../types/billing/PaymentCard";
import { SubscriptionType } from "../types/billing/SubscriptionType";

const init = createAction(
    'upgradeSubscription/INIT',
    resolve => (targetSubscriptionType: SubscriptionType, billingContact: BillingContact, paymentCard: PaymentCard) =>
        resolve({ targetSubscriptionType, billingContact, paymentCard })
);
const reset = createAction('upgradeSubscription/RESET');
const subscriptionSavingFlag = createAction(
    'upgradeSubscription/SUBSCRIPTION_SAVING_FLAG',
    resolve => (isSubscriptionSaving: boolean) => resolve({ isSubscriptionSaving })
);
const save = createAction(
    'upgradeSubscription/SAVE',
    resolve => (targetSubscriptionType: SubscriptionType) =>
        resolve({ targetSubscriptionType })
);

export const upgradeSubscriptionActions = {
    init,
    reset,
    subscriptionSavingFlag,
    save
}
